var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    {
      attrs: {
        title: _vm.$t("common.close-text", { text: _vm.$t("lbl_amortization") })
      }
    },
    [
      _c(
        "a-form-model",
        {
          attrs: {
            "label-align": "left",
            "wrapper-col": { span: 12 },
            "label-col": { span: 9 },
            "data-testid": "close-amortization-form"
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c("a-form-model-item", {
                    attrs: { label: _vm.$t("lbl_amortization_number") }
                  }),
                  _c("a-form-model-item", {
                    attrs: { label: _vm.$t("lbl_transaction_type") }
                  }),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_unit_code") } },
                    [
                      _c("SelectUnitCode", {
                        attrs: {
                          "label-in-value": "",
                          "data-testid": "close-amortization-unit-code"
                        },
                        model: {
                          value: _vm.formData.unitCode,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "unitCode", $$v)
                          },
                          expression: "formData.unitCode"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_closing_date") } },
                    [
                      _c("a-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          format: _vm.DEFAULT_DATE_FORMAT,
                          "allow-clear": "",
                          "data-testid": "close-amortization-closing-date"
                        },
                        model: {
                          value: _vm.formData.closingDate,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "closingDate", $$v)
                          },
                          expression: "formData.closingDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: { label: _vm.$t("lbl_description") },
                      scopedSlots: _vm._u([
                        {
                          key: "extra",
                          fn: function() {
                            return [
                              _c("character-length", {
                                attrs: { value: _vm.formData.description }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c("a-textarea", {
                        attrs: {
                          "data-testid": "close-amortization-description"
                        },
                        model: {
                          value: _vm.formData.description,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "description", $$v)
                          },
                          expression: "formData.description"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c("a-form-model-item", {
                    attrs: { label: _vm.$t("lbl_leasing_number") }
                  }),
                  _c("a-form-model-item", {
                    attrs: { label: _vm.$t("lbl_leasing_contract_number") }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c("a-table", {
                    attrs: {
                      size: "small",
                      "data-source": [],
                      columns: _vm.columns,
                      scroll: { x: 1200 },
                      "row-class-name": function(_record, index) {
                        return index % 2 ? "bg-white" : "bg-gray-light"
                      },
                      "data-testid": "close-amortization-table"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24, align: "end" } },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            "data-testid": "close-amortization-btn-back"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            "data-testid": "close-amortization-btn-close"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("lbl_close")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }