import {
  buildTruckingCashInOutFormLineState,
  buildTruckingCashInOutFormState,
} from "@/builder/trucking-cash-in-out/TruckingCashInOutStateBuilder";
import useDate from "@/hooks/useDate";
import usePreferences from "@/hooks/usePreferences";
import { DATE_FORMAT_yyyy_MM_DD } from "@/models/constants/date.constant";
import {
  TruckingCashInOutFormLineState,
  TruckingCashInOutFormState,
} from "@/models/interface/trucking-cash-in-out";
import {
  TruckingPaymentDownloadParam,
  TruckingPaymentListFilterState,
  TruckingPaymentListParam,
  TruckingPaymentResponseDto,
} from "@/models/interface/trucking-payment";
import store from "@/store";
import moment, { Moment } from "moment";

export class TruckingPaymentMapper {
  static paginationParamToParamDownload(
    state: TruckingPaymentListFilterState,
    query: TruckingPaymentListParam
  ): TruckingPaymentDownloadParam {
    const companyName = store.state["authStore"].authData.companyName || "";

    let paymentDate = "ALL";
    if (state.paymentDate && state.paymentDate.length > 0) {
      const [start, end] = state.paymentDate;
      paymentDate = `${useDate()
        .toStartDay(start)
        .format(DATE_FORMAT_yyyy_MM_DD)} - ${useDate()
        .toEndDay(end)
        .format(DATE_FORMAT_yyyy_MM_DD)}`;
    }

    const paymentNumber = state.paymentNumber || "ALL";
    const ujoNumber = state.ujoNumber || "ALL";
    const driverName = state.driverName || "ALL";
    const isPosted = state.posting || "ALL";
    const cashInOutNumber = state.docReference || "ALL";
    const companyBank = state.companyBank?.label || "ALL";

    const header = [
      companyName,
      paymentDate,
      paymentNumber,
      ujoNumber,
      driverName,
      isPosted,
      cashInOutNumber,
      companyBank,
    ];

    return {
      ...query,
      params: header.join(","),
    };
  }

  static toTruckingCashInOutFormLineState(
    data: TruckingPaymentResponseDto
  ): TruckingCashInOutFormLineState {
    const state: TruckingCashInOutFormLineState =
      buildTruckingCashInOutFormLineState();

    state.amount = data.amount;
    state.notes = data.description;
    state.truckingPaymentId = data.id;

    const preference = usePreferences().findByKey("trucking_down_payment");
    if (preference && preference.value) {
      state.account = { key: preference.value, label: preference.name };
    }

    return state;
  }

  static toTruckingCashInOutFormState(
    data: TruckingPaymentResponseDto[],
    paymentDate?: Moment
  ): TruckingCashInOutFormState {
    const state: TruckingCashInOutFormState = buildTruckingCashInOutFormState();
    const TRANSFER_TO = "Driver";

    state.type = "TRUCKING_OUT";
    state.transactionDate = paymentDate
      ? paymentDate
      : moment(data[0].paymentDate);
    state.companyBank = {
      key: data[0].sourceBankId,
      label: data[0].sourceBank,
    };
    state.cashInOutTransfer = "NONE";
    state.transferOthers = TRANSFER_TO;
    state.lines = data.map(
      TruckingPaymentMapper.toTruckingCashInOutFormLineState
    );

    const preference = usePreferences().findByKey("feature_base_currency");
    if (preference && preference.value) {
      state.currency = { key: preference.value, label: preference.name };
    }

    return state;
  }
}
