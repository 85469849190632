












































































import SelectUnitCode from "@/components/custom/select/SelectUnitCode.vue";
import currencyFilter from "@/filters/currency.filter";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { CloseAmortizationForm } from "@/models/interface/amortization";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    SelectUnitCode,
  },
})
export default class CloseAmortization extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;

  columns = [
    {
      title: this.$t("lbl_no"),
      dataIndex: "no",
      key: "no",
    },
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCode",
      key: "unitCode",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      key: "serialNumber",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_acquisition_date"),
      dataIndex: "acquisitionDate",
      key: "acquisitionDate",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_brand"),
      dataIndex: "brand",
      key: "brand",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_type"),
      dataIndex: "type",
      key: "type",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_note"),
      dataIndex: "note",
      key: "note",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_year"),
      dataIndex: "year",
      key: "year",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_start_period"),
      dataIndex: "startPeriod",
      key: "startPeriod",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_end_period"),
      dataIndex: "endPeriod",
      key: "endPeriod",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_amount"),
      dataIndex: "amount",
      key: "amount",
      customRender: text => currencyFilter(text),
    },
    {
      title: this.$t("lbl_status"),
      dataIndex: "status",
      key: "status",
      customRender: text => text || "-",
    },
  ];

  formData: CloseAmortizationForm = this.initCloseAmortizationForm();

  initCloseAmortizationForm(): CloseAmortizationForm {
    return {
      closingDate: null,
      description: undefined,
      unitCode: undefined,
    };
  }
}
